export enum ApiResultStatus {
  Ok = 1,
  InternalServerError = 2,
  SecurityKeyNotProvided = 3,
  IncorrectSecurityKey = 4,
  UserNotFound = 5,
  IncorrectAuthorizationToken = 6,
  InsufficientPermissions = 7,
  IncorrectGuid = 8,
  PasswordExpired = 9,
}

export enum UserRoleType {
  Admin = 1,
  Normal = 2,
  Menager = 3,
  CompanyUser = 4,
  BrandUser = 5,
  Dealer = 6,
}

export enum EmployeeDeliveryStatus {
  Avaliable = 1,
  OnRoad = 2,
  Returning = 3
}

export enum MarkerType {
  OrderInformation = 1,
  EmployeeInformation = 2,
}

export enum OrderDeliveryStatus {
  ToBeDelivered = 1,
  Delivered = 2,
  NoOneOpened = 3,
}

export enum OrderDeliveryStatusSource
{
    None = 1,
    GPS = 2,
    MobileConfirmed = 3
}

export enum PaymentType {
  Other = 0,
  Cash = 1,
  Card = 2,
  Online = 3,
  Voucher = 4,
}

export enum PaymentStatus {
  None = 0,
  Accepted = 1,
  Rejected = 2,
  Cancelled = 3,
}
