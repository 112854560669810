import { useBaseApi } from "api/base-api";
import { IResultBase, IResultBaseGeneric } from "api/interfaces";
import _ from "lodash";
import { IOrderApi } from "./interfaces";
import {
  IOrderGetDetailsModel,
  IOrderGetListModel,
  IOrderSetEmployeeModel,
} from "./interfaces-model";
import {
  IOrderGetDetailsResult,
  IOrderGetListResult,
} from "./interfaces-result";

export const useOrderApi: () => IOrderApi = (): IOrderApi => {
  const { baseGetRequestWithModel, basePostRequest } =
    useBaseApi();

  const getList: (
    model: IOrderGetListModel
  ) => Promise<IResultBaseGeneric<IOrderGetListResult[]>> = async (
    model: IOrderGetListModel
  ): Promise<IResultBaseGeneric<IOrderGetListResult[]>> => {
    let url: string = "/Order/GetList";
    return await baseGetRequestWithModel<
      IOrderGetListModel,
      IResultBaseGeneric<IOrderGetListResult[]>
    >(url, model);
  };

  const getDetails: (
    model: IOrderGetDetailsModel
  ) => Promise<IResultBaseGeneric<IOrderGetDetailsResult>> = async (
    model: IOrderGetDetailsModel
  ): Promise<IResultBaseGeneric<IOrderGetDetailsResult>> => {
    let url: string = "/Order/GetDetails";
    return await baseGetRequestWithModel<
      IOrderGetDetailsModel,
      IResultBaseGeneric<IOrderGetDetailsResult>
    >(url, model);
  };

  const setEmployee: (model: IOrderSetEmployeeModel) => Promise<IResultBase> =
    async (model: IOrderSetEmployeeModel): Promise<IResultBase> => {
      let url: string = "/Order/SetEmployee";
      return await basePostRequest<IOrderSetEmployeeModel, IResultBase>(
        url,
        model
      );
    };

  return { getList, getDetails, setEmployee };
};
