import {
  Alert,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from "@mui/material";
import { EmployeeSelect } from "components/employee/employee-select";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getFormattedAddress } from "utils/helpers";
import { IOrderListProps } from "./interfaces";
import { setOrderId, useOrderId } from "./order-events";
import { GpsFixed, PanTool, Place, Schedule } from "@mui/icons-material";
import { css } from "@emotion/css";
import { OrderDeliveryStatus, OrderDeliveryStatusSource } from "utils/enums";

export const OrderList: React.FC<IOrderListProps> = (
  props: IOrderListProps
) => {
  const { t } = useTranslation(["orderTranslation"]);
  const theme = useTheme();
  const orderList = props.orderList;

  const selectedOrderId = useOrderId();

  return (
    <>
      <Paper
        variant="elevation"
        elevation={3}
        sx={{ padding: "1rem", height: "100%" }}
      >
        {orderList.length === 0 && (
          <Grid container justifyContent={"left"}>
            <Grid item xs={12}>
              <Alert severity="info">{t("noOrders")}</Alert>
            </Grid>
          </Grid>
        )}
        {orderList.length > 0 && (
          <TableContainer sx={{ height: "100%" }}>
            <Table
              sx={{
                // minWidth: 650,
                height: "max-content",
                "& .MuiTableCell-sizeSmall": {
                  padding: "0 5px",
                },
              }}
              stickyHeader={true}
              size="small"
              aria-label="a dense table"
              className={css`
                .muitablecell-sizesmall: {
                  padding: 0 !important;
                }
              `}
            >
              <TableBody sx={{ width: "100%" }}>
                {_.map(orderList, (data) => (
                  <TableRow
                    key={`order-list-order-id-${data.orderId}`}
                    sx={{
                      backgroundColor:
                        data.orderId === selectedOrderId
                          ? theme.palette.primary.light
                          : "none",
                    }}
                    onClick={() => {
                      setOrderId(
                        data.orderId === selectedOrderId ? 0 : data.orderId
                      );
                    }}
                  >
                    <TableCell padding="none" align="center" width={"10px"}>
                      {data.orderDailyNumber && `{${data.orderDailyNumber}}`}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        minWidth: "40px"
                      }}
                    >
                      <div>
                        <div
                          className={css`
                            display: inline-block;
                          `}
                        >
                          {moment(data.orderDate).format("HH:mm")} -{" "}
                        </div>
                        <div
                          className={css`
                            display: inline-block;
                          `}
                        >
                          {moment(data.expectedDeliveryDate).format("HH:mm")}
                        </div>
                        <div
                          className={css`
                            display: inline-block;
                            margin-top: -6px;
                          `}
                        >
                          {data.isOnTimeDelivery && (
                            <Schedule fontSize="small" color="warning" />
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{getFormattedAddress(data)}</TableCell>
                    <TableCell
                      sx={{
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                      }}
                      width={"10px"}
                    >
                      {data.deliveryStatusSource ===
                        OrderDeliveryStatusSource.MobileConfirmed && (
                        <PanTool fontSize="small" />
                      )}
                      {data.deliveryStatusSource ===
                        OrderDeliveryStatusSource.GPS && (
                        <Place fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell>
                      {data.employeeFirstName}{" "}
                      {data.employeeLastName
                        ? `${data.employeeLastName.substring(0, 2)}.`
                        : ""}
                    </TableCell>
                    {props.isAssignDriverEnabled && (
                      <TableCell>
                        <EmployeeSelect
                          orderId={data.orderId}
                          employeeList={props.employeeList}
                          selectedEmloyeeId={data.employeeId}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};
