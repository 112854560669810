import {
  faArrowRight,
  faClock,
  faClockRotateLeft,
  faHelicopter,
  faHourglassEnd,
  faHourglassStart,
  faMeh,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Grid } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "react-leaflet";
import {
  getOrderDeliveryStatusEnumTranslation,
  getPaymentTypeEnumTranslation,
} from "utils/enums-helper";
import { IOrderDetailsPopupProps } from "./interfaces";

export const OrderDetailsPopup: React.FC<IOrderDetailsPopupProps> = (
  props: IOrderDetailsPopupProps
) => {
  const { t } = useTranslation(["enumTranslation", "orderTranslation"]);
  const [address, setAddress] = useState<string>("-");
  const [additionalInfo, setAdditionalInfo] = useState<string>("");
  const [employeeName, setEmployeeName] = useState<string>("");

  const formatAddress: () => void = (): void => {
    if (props.orderDetails) {
      let newAddress: string = "";
      newAddress +=
        props.orderDetails.street == null
          ? " "
          : `${props.orderDetails.street} `;
      newAddress +=
        props.orderDetails.streetNo == null
          ? "-/"
          : props.orderDetails.streetNo;
      newAddress +=
        props.orderDetails.apartmentNo == null
          ? ", "
          : `/${props.orderDetails.apartmentNo}, `;
      newAddress +=
        props.orderDetails.zipCode == null ||
        props.orderDetails.zipCode === "00-000" ||
        _.trim(props.orderDetails.zipCode) === ""
          ? "\n"
          : `\n${props.orderDetails.zipCode}, `;
      newAddress += props.orderDetails.city ?? "";
      newAddress += props.orderDetails.buildingNo
        ? `\nBlok: ${props.orderDetails.buildingNo}`
        : "";
      newAddress +=
        props.orderDetails.buildingNo && props.orderDetails.floorNo
          ? `; Piętro: ${props.orderDetails.floorNo}`
          : "";
      setAddress(newAddress);
    }
  };

  const formatAdditionalInfo: () => void = (): void => {
    if (props.orderDetails) {
      let newAddtionalInfo: string = _.trim(
        _.trim(
          `${props.orderDetails.customerFirstName ?? ""} ${
            props.orderDetails.customerLastName ?? ""
          } - ${props.orderDetails.customerCompanyName ?? ""}`,
          " "
        ),
        "-"
      );
      setAdditionalInfo(newAddtionalInfo);
    }
  };

  const formatEmployeeName: () => void = (): void => {
    if (props.orderDetails) {
      let newEmployeeName: string = `${
        props.orderDetails.employeeFirstName ?? "-"
      }${
        props.orderDetails.employeeLastName
          ? " " + props.orderDetails.employeeLastName
          : ""
      }`;
      setEmployeeName(newEmployeeName);
    }
  };

  useEffect(() => {
    formatAddress();
    formatAdditionalInfo();
    formatEmployeeName();
  }, [props.orderDetails]);

  return (
    <>
      <Popup>
        <Grid container sx={{ width: 250 }} rowSpacing={0.5}>
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faPhone} />
          </Grid>
          <Grid item xs={10}>
            {props.orderDetails?.clientPhoneNo ?? "-"}
          </Grid>
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faArrowRight} />
          </Grid>
          <Grid item xs={10}>
            {address}
          </Grid>
          {additionalInfo && (
            <>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                {additionalInfo}
              </Grid>
            </>
          )}
          {additionalInfo && props.orderDetails?.customerComment && (
            <>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                {props.orderDetails?.customerComment}
              </Grid>
            </>
          )}
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faMeh} />
          </Grid>
          <Grid item xs={10} sx={{ fontStyle: "italic" }}>
            {props.orderDetails?.addressComment && (
              <p style={{ padding: 0, margin: 0 }}>
                "{props.orderDetails?.addressComment}"
              </p>
            )}
            {props.orderDetails?.orderComment && (
              <p style={{ padding: 0, margin: 0 }}>
                "{props.orderDetails?.orderComment}"
              </p>
            )}
          </Grid>
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faHelicopter} />
          </Grid>
          <Grid item xs={10}>
            {employeeName}
          </Grid>
          {props.orderDetails?.employeeComment && (
            <>
              <Grid item xs={2}></Grid>
              <Grid item xs={10} sx={{ fontStyle: "italic" }}>
                "{props.orderDetails.employeeComment}"
              </Grid>
            </>
          )}
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faClock} />
          </Grid>
          <Grid item xs={10}>
            {props.orderDetails?.orderDate
              ? moment(props.orderDetails?.orderDate).format("HH:mm")
              : "--:--"}
          </Grid>
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faClockRotateLeft} />
          </Grid>
          <Grid item xs={10}>
            {props.orderDetails?.expectedDeliveryDate
              ? moment(props.orderDetails?.expectedDeliveryDate).format("HH:mm")
              : "--:--"}
          </Grid>
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faHourglassStart} />
          </Grid>
          <Grid item xs={10}>
            {props.orderDetails?.startDeliveryDate
              ? moment(props.orderDetails?.startDeliveryDate).format("HH:mm")
              : "--:--"}
          </Grid>
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faHourglassEnd} />
          </Grid>
          <Grid item xs={10}>
            {props.orderDetails?.deliveryDate
              ? moment(props.orderDetails?.deliveryDate).format("HH:mm")
              : "--:--"}
          </Grid>
          <Grid item xs={3} sx={{ fontWeight: "bold" }}>
            {t("orderTranslation:status")}:
          </Grid>
          <Grid item xs={9} sx={{ textAlign: "right" }}>
            {props.orderDetails?.deliveryStatus
              ? _.toUpper(
                  getOrderDeliveryStatusEnumTranslation(
                    props.orderDetails.deliveryStatus,
                    t
                  )
                )
              : ""}
          </Grid>
          <Grid item xs={3} sx={{ fontWeight: "bold" }}>
            {t("orderTranslation:amount")}:
          </Grid>
          <Grid item xs={9} sx={{ textAlign: "right" }}>
            {props.orderDetails?.orderCost
              ? `${props.orderDetails.orderCost} zł`
              : "-"}
          </Grid>
          <Grid item xs={3} sx={{ fontWeight: "bold" }}>
            {t("orderTranslation:payment")}:
          </Grid>
          <Grid item xs={9} sx={{ textAlign: "right" }}>
            {props.orderDetails?.paymentType
              ? getPaymentTypeEnumTranslation(props.orderDetails.paymentType, t)
              : ""}
          </Grid>
          {props.orderDetails?.dailyNumber && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                {props.orderDetails.dailyNumber}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {(props.orderDetails?.orderItemList?.length ?? 0) > 0 &&
            _.map(props.orderDetails?.orderItemList, (value) => {
              let quantity: string = value.quantity ? `${value.quantity}x` : "";
              let productSize: string = value.productSize
                ? `(${_.trim(value.productSize)})`
                : "";
              return (
                <>
                  <Grid item xs={2}>
                    {quantity}
                  </Grid>
                  <Grid item xs={10}>
                    {value.productName} {productSize}
                  </Grid>
                  {value.productDetails && (
                    <>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={10}>
                        {value.productDetails}
                      </Grid>
                    </>
                  )}
                </>
              );
            })}
        </Grid>
      </Popup>
    </>
  );
};
