import { Container, Grid } from "@mui/material";
import { OrderList } from "components/order/order-list";
import "leaflet/dist/leaflet.css";
import { EmployeeList } from "components/employee/employee-list";
import { MapBasic } from "components/map-basic";
import { useOrderApi } from "api/OrderApi/order-api";
import { ApiResultStatus, OrderDeliveryStatus } from "utils/enums";
import { Suspense, useEffect, useState } from "react";
import _ from "lodash";
import { IMarker } from "components/interfaces";
import { Subscribe } from "@react-rxjs/core";
import { useEmployeeApi } from "api/EmployeeApi/employee-api";
import { useEmployeeId } from "components/employee/employee-events";
import { useOrderEmplyeeChanged } from "components/order/order-events";
import { REFRESH_DATA_INTERVAL_IN_MILISECONDS } from "utils/consts";
import { useTranslation } from "react-i18next";
import { useLocationApi } from "api/LocationApi/location-api";
import { ILocationGetDetailsResult } from "api/LocationApi/interfaces-result";
import { IOrderGetListResult } from "api/OrderApi/interfaces-result";
import { IEmployeeGetListResult } from "api/EmployeeApi/interfaces-result";
import { OrderListFilter } from "components/order/order-list-filter";

export const Main: () => JSX.Element = (): JSX.Element => {
  const { getList: getOrderList } = useOrderApi();
  const { getList: getEmployeeList } = useEmployeeApi();
  const { getDetails: getLocationDetails } = useLocationApi();
  const [employeeList, setEmployeeList] = useState<IEmployeeGetListResult[]>([]);
  const [orderList, setOrderList] = useState<IOrderGetListResult[]>([]);
  const [orderMarkerList, setOrderMarkerList] = useState<IMarker[]>([]);
  const [employeeMarkerList, setEmployeeMarkerList] = useState<IMarker[]>([]);
  const [locationDetails, setLocationDetails] = useState<ILocationGetDetailsResult>();
  const [showMap, setShowMap] = useState<boolean>(() => {
    let saved = localStorage.getItem("showMap") ?? 'true';
    return saved === 'true';
  });
  const [showWithoutEmployee, setShowWithoutEmployee] = useState<boolean>(() => {
    let saved = localStorage.getItem("showWithoutEmployee") ?? 'true';
    return saved === 'true';
  });
  const [showDeliveredOrder, setShowDeliveredOrder] = useState<boolean>(() => {
    let saved = localStorage.getItem("showDeliveredOrder") ?? 'true';
    return saved === 'true';
  });
  const { t } = useTranslation(["commonTranslation"]);

  const selectedEmployeeId = useEmployeeId();
  const orderEmployeeChanged = useOrderEmplyeeChanged();

  const handleGetLocalizationDetails: () => void = (): void => {
    getLocationDetails().then((response) => {
      if (response?.status === ApiResultStatus.Ok) {
        setLocationDetails(response.data);
      }
    });
  };

  useEffect(() => {
    handleGetLocalizationDetails();
  }, []);

  const loadOrderList: () => void = (): void => {
    getOrderList({
      showDeliveredOrder: showDeliveredOrder,
      showWithoutEmployee: selectedEmployeeId ? false : showWithoutEmployee,
      employeeId: selectedEmployeeId ? selectedEmployeeId : undefined,
    }).then((response) => {
      if (response?.status === ApiResultStatus.Ok) {
        setOrderList(response.data);

        const newOrderMarkerList: IMarker[] = _.map(
          _.filter(response.data, (o) => {
            return !!o.longitude && !!o.latitude;
          }),
          (o) => {
            let newMarker: IMarker = {
              id: o.orderId,
              latitude: o.latitude!,
              longitude: o.longitude!,
              tooltipText: o.orderDailyNumber,
              color:
                o.deliveryStatus == OrderDeliveryStatus.Delivered
                  ? "#008000"
                  : undefined,
            };
            return newMarker;
          }
        );
        setOrderMarkerList(newOrderMarkerList);
      }
    });
  };

  const loadEmployeeData: () => void = (): void => {
    getEmployeeList().then((response) => {
      if (response?.status === ApiResultStatus.Ok) {
        setEmployeeList(response.data);
      }
    });
  };

  useEffect(() => {
    if (orderEmployeeChanged.orderId > 0) {
      loadOrderList();
    }
  }, [orderEmployeeChanged]);

  useEffect(() => {
    loadOrderList();
  }, [selectedEmployeeId, showDeliveredOrder, showWithoutEmployee]);

  useEffect(() => {
    const newEmployeeMarkerList: IMarker[] = _.map(
      _.filter([...employeeList], (e) => {
        return (
          !!e.lastDeviceLocalizationLongitude &&
          !!e.lastDeviceLocalizationLatitude
        );
      }),
      (e) => {
        let newMarker: IMarker = {
          id: e.id,
          latitude: e.lastDeviceLocalizationLatitude!,
          longitude: e.lastDeviceLocalizationLongitude!,
          color: e.color,
          tooltipText: e.phoneNo,
        };
        return newMarker;
      }
    );
    setEmployeeMarkerList(newEmployeeMarkerList);
  }, [employeeList]);

  useEffect(() => {
    loadOrderList();
    loadEmployeeData();
    setInterval(() => {
      loadOrderList();
    }, REFRESH_DATA_INTERVAL_IN_MILISECONDS);

    setInterval(() => {
      loadEmployeeData();
    }, REFRESH_DATA_INTERVAL_IN_MILISECONDS / 4);
  }, []);

  return (
    <Container maxWidth={false}>
      <Subscribe>
        <Suspense fallback={<p>wait</p>}>
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={showMap ? 6 : 12}
              spacing={1}
              height="95vh"
            >
              <Grid item xs={12}>
                {locationDetails && (
                  <OrderListFilter
                    defaultOrderReadyTimeInMinutes={
                      locationDetails.defaultOrderReadyTimeInMinutes
                    }
                    showDeliveredOrder={showDeliveredOrder}
                    showMap={showMap}
                    showWithoutEmployee={showWithoutEmployee}
                    handleShowMapOnClick={(showMap: boolean) => {
                      setShowMap(showMap);
                      localStorage.setItem("showMap", showMap.toString());
                    }}
                    handleSetShowDeliveredOrderOnClick={(
                      isChecked: boolean
                    ) => {
                      setShowDeliveredOrder(isChecked);
                      localStorage.setItem(
                        "showDeliveredOrder",
                        isChecked.toString()
                      );
                    }}
                    handleSetShowWithoutEmployeeOnClick={(
                      isChecked: boolean
                    ) => {
                      setShowWithoutEmployee(isChecked);
                      localStorage.setItem(
                        "showWithoutEmployee",
                        isChecked.toString()
                      );
                    }}
                    handleSetDefaultOrderReadyTimeInMinutesOnSave={(
                      defaultOrderReadyTimeInMinutes: number
                    ) => {
                      setLocationDetails({
                        ...locationDetails,
                        defaultOrderReadyTimeInMinutes:
                          defaultOrderReadyTimeInMinutes,
                      });
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={showMap ? 12 : 6}
                height={showMap ? "130px" : "calc(98vh - 100px)"}
              >
                <EmployeeList
                  employeeList={employeeList}
                  itemSize={6} /*{showMap ? 6 : 4}*/
                  isSingleRowMode={showMap}
                />
              </Grid>
              <Grid
                item
                xs={showMap ? 12 : 6}
                height={showMap ? "calc(98vh - 225px)" : "calc(98vh - 100px)"}
              >
                {locationDetails && (
                  <OrderList
                    orderList={orderList}
                    employeeList={employeeList}
                    isAssignDriverEnabled={
                      locationDetails.isAssignDriverEnabled
                    }
                  />
                )}
              </Grid>
            </Grid>
            {showMap && locationDetails && (
              <Grid container item xs={6} height="98vh">
                <Grid item xs={12}>
                  <MapBasic
                    orderMarkerList={orderMarkerList}
                    employeeMarkerList={employeeMarkerList}
                    locationDetails={locationDetails}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Suspense>
      </Subscribe>
    </Container>
  );
};
