import { bind } from "@react-rxjs/core";
import { createSignal } from "@react-rxjs/utils";
import { IOrderSetEmployeeModel } from "api/OrderApi/interfaces-model";
import { map } from "rxjs/operators";

export const [orderClick$, setOrderId] = createSignal<number>();
export const [useOrderId, orderId$] = bind(
  orderClick$.pipe(
    map((value) => {
      return value;
    })
  ),
  0
);

export const [orderEmployee$, setOrderEmployee] =
  createSignal<IOrderSetEmployeeModel>();
export const [useOrderEmplyeeChanged, orderEmployeeChanged$] = bind(
  orderEmployee$.pipe(
    map((value) => {
      return value;
    })
  ),
  {
    orderId: 0,
    employeeId: 0,
  }
);
